<template>
  <div id="news-m">
    <div class="title">
      <div class="title-top">新闻中心</div>
      <div class="title-description">了解有关晓果智学的所有最新公司动态</div>
    </div>
    <!-- 内容区 -->
    <div class="main">
      <div
        v-loading="showNewsLoading"
        v-for="(item, index) in newsList"
        :key="item.id"
        @click="clickme(item, index)"
        class="news-item-contain"
      >
        <div class="news-item">
          <!-- 左侧图片 -->
          <div class="left">
            <img :src="baseUrl + item.imgUrl" alt="" />
          </div>
          <!-- 右侧信息 -->
          <div class="right">
            <div class="right-1">
              {{ item.title }}
            </div>
            <div class="right-2">
              <div class="right-2-1">
                <img :src="fontAssetsURL(`news/图层 905.png`)" alt="" />
                {{ item.pushTime }}
              </div>
              <div class="right-2-2">
                <img :src="fontAssetsURL(`news/图层 906.png`)" alt="" />
                {{ item.count }}
              </div>
            </div>
            <!-- <div class="right-3 ql-editor" v-html="item.content">
          </div> -->
          </div>
        </div>
      </div>
      <div class="showLoading">加载中...</div>
    </div>
    <div class="common-pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="pageSize"
        :total="total"
        @current-change="currentChange($event)"
        @prev-click="prevClick($event)"
        @next-click="nextClick($event)"
        hide-on-single-page
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getNewsList, getCount } from "../server/api";

const defautListQuery = {
  pageSize: 10,
  pageNum: 1,
};

export default {
  data() {
    return {
      showNewsLoading:false,
      newsList: [],
      listQuery: Object.assign({}, defautListQuery),
      baseUrl: "https://www.xiaoguoai.cn/",
      total: 0,
      pageSize: Number(10),
    };
  },
  created() {
    this.getData(); //获取文章列表
    // 分页回到首页
    this.$bus.$on("back", () => {
      this.getData();
    });
  },
  methods: {
    // 分页
    currentChange(e) {
      this.getData({ pageSize: 10, pageNum: e });
    },
    // 分页pre
    prevClick(e) {
      this.getData({ pageSize: 10, pageNum: e });
    },
    // 分页next
    nextClick(e) {
      this.getData({ pageSize: 10, pageNum: e });
    },
    getData(param) {
      this.showNewsLoading = true;
      let data = param || this.listQuery;
      getNewsList(data).then((res) => {
        this.newsList = res.data.list; //列表
        this.total = res.data.total; //条数
        // 空数据高度获取
        if (this.newsList.length == 0) {
          let w = document.documentElement.clientHeight;
          document.querySelector(".main").style.height = w - 693 + "px";
        }
        this.showNewsLoading = false;
      });
    },
    //跳转到详情页
    clickme(val) {
      //观看次数请求
      getCount({ id: val.id });
      //观看次数+1
      val.count++;
      this.$router.push({
        path: "/newsinfo",
        query: val,
      });
    },
  },
};
</script>

<style scoped>
@import "../style_mobile/news.css";
</style>
